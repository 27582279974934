<template>
  <asterix-application-layout>
    <template #sidebar>
      <asterix-sidebar v-model="isSidebarOpen">
        <sun-sidebar-group class="mt-8">
          <sun-sidebar-nav-item :to="cluster">
            <template #icon><analytics-svg class="w-4" /></template>Clusters list
          </sun-sidebar-nav-item>
        </sun-sidebar-group>
      </asterix-sidebar>
    </template>

    <template #header>
      <asterix-topbar class="z-20" @change="isSidebarOpen = !isSidebarOpen" />
    </template>
  </asterix-application-layout>
</template>

<script>
import AsterixApplicationLayout from '@/components/templates/AsterixApplicationLayout';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import SunSidebarNavItem from '@/components/molecules/SunSidebarNavItem';
import AnalyticsSvg from '@/components/icons/AnalyticsSvg';
import { cluster } from '@/router/private/modules/contextual/cluster';

export default {
  name: 'ContextualIndex',
  components: {
    AsterixApplicationLayout,
    AsterixSidebar,
    AsterixTopbar,
    SunSidebarNavItem,
    AnalyticsSvg,
  },
  data() {
    return {
      isSidebarOpen: true,
      cluster,
    };
  },
};
</script>
