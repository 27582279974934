var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-application-layout", {
    scopedSlots: _vm._u([
      {
        key: "sidebar",
        fn: function () {
          return [
            _c(
              "asterix-sidebar",
              {
                model: {
                  value: _vm.isSidebarOpen,
                  callback: function ($$v) {
                    _vm.isSidebarOpen = $$v
                  },
                  expression: "isSidebarOpen",
                },
              },
              [
                _c(
                  "sun-sidebar-group",
                  { staticClass: "mt-8" },
                  [
                    _c(
                      "sun-sidebar-nav-item",
                      {
                        attrs: { to: _vm.cluster },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("analytics-svg", { staticClass: "w-4" }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [_vm._v("Clusters list ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "header",
        fn: function () {
          return [
            _c("asterix-topbar", {
              staticClass: "z-20",
              on: {
                change: function ($event) {
                  _vm.isSidebarOpen = !_vm.isSidebarOpen
                },
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }